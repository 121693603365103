import {
  PAYMENT_REQUEST_ALL,
  PAYMENT_REQUEST_AWAITING_APPROVE,
  PAYMENT_REQUEST_CANCELLED,
  PAYMENT_REQUEST_CREATED,
  PAYMENT_REQUEST_DEBT,
  PAYMENT_REQUEST_PAID,
  PAYMENT_REQUEST_REJECTED_INVOICE,
} from 'utils/paymentRequest'

const PAYMENT_REQUEST_STATUSES_VI = {
  [PAYMENT_REQUEST_ALL]: 'TẤT CẢ',
  [PAYMENT_REQUEST_CREATED]: 'MỚI',
  [PAYMENT_REQUEST_PAID]: 'ĐÃ THANH TOÁN',
  [PAYMENT_REQUEST_CANCELLED]: 'ĐÃ HỦY',
  [PAYMENT_REQUEST_AWAITING_APPROVE]: 'CHỜ DUYỆT',
  [PAYMENT_REQUEST_DEBT]: 'GHI NỢ',
  [PAYMENT_REQUEST_REJECTED_INVOICE]: 'TỪ CHỐI HÓA ĐƠN',
}

export const PAYMENT_REQUEST_VI = {
  title: 'Quản lý Yêu cầu thanh toán',
  vigoQuotationNumber: 'Mã báo giá Vigo',
  detail: 'Chi tiết báo giá',
  statusHistory: 'Lịch sử thay đổi',
  no: 'Thứ tự',
  createdDate: 'Ngày tạo',
  updatedDate: 'Ngày cập nhật',
  updateBy: 'Người cập nhật',
  relatedDocuments: 'Thông tin liên quan',
  deliveryTo: 'Vận chuyển đến',
  quotationDetails: 'Chi tiết báo giá',
  shipment: 'Tuyến hàng',
  vigoQuotation: 'Báo giá từ Vigo',
  deliveredBy: 'Giao hàng bởi',
  noOfItem: 'Số lượng',
  totalAmount: 'Tổng tiền',
  image: 'Hình ảnh',
  name: 'Tên sản phẩm',
  cancelled: 'ĐÃ HỦY',
  paid: 'ĐÃ THANH TOÁN',
  actualOfferPrice: 'Giá đề nghị thực tế',
  selectWarehouse: 'Chọn kho hàng',
  vigoOfferPrice: 'Giá đề nghị từ Vigo',
  requestedQuantity: 'Số lượng yêu cầu',
  actualTotalAmount: 'Tổng giá trị thực tế',
  totalPriceDifferent: 'Tổng giá chênh lệch',
  packType: 'Đơn vị đóng gói',
  estimatedWeight: 'Trọng lượng ước tính',
  confirm: 'Xác nhận',
  warehouse: 'Kho hàng',
  distributor: 'Nhà phân phối',
  creationTime: 'Thời gian tạo',
  startDate: 'Ngày bắt đầu',
  endDate: 'Ngày kết thúc',
  search: 'Tìm kiếm',
  reset: 'Cài lại',
  refresh: 'Làm mới',
  currentStatus: 'Trạng thái hiện tại',
  requestDate: 'Ngày yêu cầu',
  confirmDeadline: 'Thời gian xác nhận',
  totalValue: 'Tổng giá trị',
  status: 'Trạng thái thanh toán',
  prev: 'Trang trước',
  next: 'Trang kế tiếp',
  paymentRequestNumber: 'Mã yêu cầu thanh toán',
  relatedDocument: 'Tài liệu liên quan',
  paymentRequest: 'Yêu cầu thanh toán',
  paymentFrom: 'Thanh toán từ',
  paymentTo: 'Thanh toán cho',
  partner: 'Đối tác liên quan',
  paymentDetails: 'Chi tiết thanh toán',
  noteFromVigoFinance: 'Ghi chú từ kế toán Vigo',
  distributorPO: 'Đơn mua hàng',
  bank: 'Ngân hàng',
  bankName: 'Tên chủ tài khoản',
  bankAccount: 'Tài khoản',
  transferNote: 'Ghi chú giao dịch',
  amount: 'Số tiền',
  selectPartner: 'Đối tác liên quan',
  dueDate: 'Hạn nợ',
  paymentFromTo: 'Thanh toán Từ - Cho',
  to: 'Cho',
  collectedAmount: 'Tiền đã thu',
  debtAmount: 'Tiền còn nợ',
  overDue: 'Quá hạn',
  recordDebt: 'Công nợ',
  maskAsPaid: 'Xác nhận thanh toán',
  relatedPerson: 'Thông tin người liên quan',
  paymentInformation: 'Thông tin thanh toán',
  noteFrom: 'Note from QA Company Distributor Finance',
  relatedInvoice: 'Hóa đơn liên quan',
  rejectInvoice: 'Từ chối hóa đơn',
  noteFromFinance: 'Ghi chú từ Bộ phận Tài chính',
  noteFromDistributor: `Ghi chú từ bộ phận tài chính {distributorName}`,
  messageCancel:
    'Bạn có chắc muốn Huỷ yêu cầu thanh toán này? Hành động này không thể hoàn tác.',
  referenceDocument: 'Tài liệu tham khảo',
  paymentNote: 'Ghi chú thanh toán',
  confirmRecordDebt: 'Xác nhận công nợ',
  debtReminder: 'Ngày nhắc nợ',
  updateInvoice: 'Cập nhật hóa đơn',
  saveNote: 'Cập nhật ghi chú',
  dueDebt: 'Khoản nợ đến hạn',
  upcomingDebt: 'Khoản nợ sắp tới',
  pleaseReminder: 'Hãy gửi nhắc nợ!',
  request: 'yêu cầu',
  awaitingResult:
    'Sau khi cập nhật hóa đơn, vui lòng nhấp vào Cập nhật và chờ kết quả.',
  upcomingDebtAndDue: 'Nợ đến hạn - sắp tới',
  payableDebt: 'Khoản nợ cần trả',
  greater_than_0: 'Lớn hơn 0 ₫ ( > 0 ₫ )',
  equal_0: 'Bằng 0 ₫  ( = 0 ₫ ) ',
  distributorPaymentDetails: 'Thanh toán Nhà phân phối',
  paymentDate: 'Ngày thanh toán',
  vigoPaymentRequest: 'Yêu cầu thanh toán mua hàng Vigo',
  nextPaymentNumber: 'Mã yêu cầu',
  validateDebt:
    'Khoảng công nợ {debtAmount} ₫ vượt hạn mức công nợ khả dụng {debtAvailable} ₫ của NPP {distributorName}!',
  collectAmount: 'Thu tiền',
  normal: 'Không giai đoạn',
  parent: 'Tổng nhiều giai đoạn',
  child: 'Giai đoạn',
  ...PAYMENT_REQUEST_STATUSES_VI,
}
