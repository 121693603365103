export const CONSOLIDATED_PR_EN = {
  created: 'CREATED',
  closed: 'CLOSED',
  cancelled: 'CANCELLED',
  all: 'ALL',
  title: 'Consolidated Purchase Request',
  consolidatedPRNumber: 'Consolidated PR Number',
  leadTime: 'Lead Time',
  createPR: 'Create Consolidated PR',
  titleConfirm: 'Confirm and send requests to suppliers',
  selectPRs: 'Select PRs',
  contentSelectPRs: 'Add Purchase Requests',
  contentSelectSuppliers: 'Choose suppliers to request for quotations',
  contentConfirm: 'Confirm and send requests to suppliers',
  addPRs: 'Add Purchase Requests',
  removeSelected: 'Remove selected',
  next: 'Next',
  contentDrawer:
    'Only Purchase Requests that are in Created status and are not included in any Consolidated PR will be shown here',
  suggestMe: 'Suggest me a list',
  requestQuotationDeadline: 'Request for Quotation deadline',
  contentPreview:
    'Please review Request for Quotations that will be sent to Suppliers',
  purchase_requests: 'Purchase Requests',
  supplier_quotations: 'Supplier Quotations',
  editQuotation: 'Edit Quotation',
  submitQuotation: 'Submit Quotation',
  viewQuotation: 'View Quotation',
  dear: 'Dear Sales Team,',
  text1: "I'm writing you on behalf of",
  text2:
    'and I would like to ask if you could send me a quotation for the following details.',
  text3:
    'It would be very kind of you if you could send us the offer as soon as possible.',
  text4: 'Kind regards!',
  deliveryInfo: 'Delivery Info',
  contentDelivery: 'We support delivery to that address:',
  quotationSubmitted: 'Quotation Submitted',
  submitSuccess: 'Submit Successfully!',
  supportDelivery: 'Delivery is supported by Supplier:',
  notSupportDelivery: 'Delivery is NOT supported by Supplier',
  viewTC: 'View T&C',
  warningClose: 'Are you sure to close this? This action cannot be undone.',
  warningCloseNotSubmit:
    'Are you sure to close this? NO QUOTATION IS SUBMITTED YET',
  closeSuccess: 'Close Consolidated PR successfully',
  validateTotalQuantity: 'Total Quotation Quantity <= Request Quantity',
}
