import {
  ADMIN_MARKETPLACE,
  DISTRIBUTOR_STAFF,
  HUB_MANAGER,
  isDistributor,
  isSaleSupervisor,
  MASTER_DISTRIBUTOR_HUB,
  SALE_SUPERVISOR,
} from 'common/config/acl'
import useACL from 'hooks/useACL'
import { IAuthentication } from 'interfaces/Authentication'
import { Distributor } from 'interfaces/distributor'
import { IUser, UserProvince } from 'interfaces/user'
import { Warehouse } from 'interfaces/warehouse'
import { get, isArray } from 'lodash'
import { useSession } from 'next-auth/react'
import { stringify } from 'qs'
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import axiosInstance from 'utils/axiosInstance'
import {
  defaultStringifyOption,
  getResponseData,
  isIndia,
} from 'utils/commonUtils'

type ContextProviderStoreType = {
  warehouses?: Warehouse[]
  distributors?: Distributor[]
  userHighLevelProvince?: UserProvince[]
  distributor?: Distributor
  loading?: boolean
}
const defaultFilters = {
  perPage: 1000,
  ignorePerPage: true,
  includeDistributor: true,
}
const initialState: ContextProviderStoreType = {
  warehouses: [],
  distributors: [],
  userHighLevelProvince: [],
}

const store = createContext<{
  state: ContextProviderStoreType
  dispatch: Dispatch<any>
}>({
  state: initialState,
  dispatch: () => null,
})
const { Provider } = store

export const GET_WAREHOUSE_DATA_ROLE_ADMIN_MARKETPLACE = 'getWarehouseData'

export const GET_DATA_WAREHOUSE_MASTER_DISTRIBUTOR_HUB = 'getDataWarehouseHub'

export const GET_DISTRIBUTOR = 'getDistributor'

export const CALL_API = 'callApi'

export const roleGetInitialData = [MASTER_DISTRIBUTOR_HUB]

const getDataWarehouse = async (email: string) => {
  try {
    const params = stringify(
      { email, includeUserWarehouse: true },
      defaultStringifyOption
    )
    const responseUser = await axiosInstance.get(`/v1/users?${params}`)
    const user = getResponseData<IUser[]>(responseUser)
    const { userWarehouse } = get(user, '[0]')
    if (isArray(userWarehouse) && userWarehouse.length > 0) {
      const ids = userWarehouse.map((item) => item?.warehouseID)
      const params = stringify(
        { ids, ...defaultFilters },
        defaultStringifyOption
      )
      const res = await axiosInstance.get(`/v1/warehouses?${params}`)
      const warehouses = getResponseData<Warehouse[]>(res)
      let existDistributor = {}
      const distributors = warehouses?.reduce((prev, curr) => {
        const { distributor } = curr
        if (existDistributor[distributor?.id]) {
          return prev
        }
        existDistributor = { ...existDistributor, [distributor?.id]: true }
        return [...prev, distributor]
      }, [])
      return { warehouses, distributors }
    }
    return { warehouses: [], distributors: [] }
  } catch (error) {
    console.log('🚀 ~ getWarehouse ~ error:', error)
  }
}

const actionReducer = (state: ContextProviderStoreType, action: any) => {
  switch (action.type) {
    case GET_WAREHOUSE_DATA_ROLE_ADMIN_MARKETPLACE: {
      return { ...state, ...action.payload }
    }
    case GET_DATA_WAREHOUSE_MASTER_DISTRIBUTOR_HUB: {
      return { ...state, ...action.payload }
    }
    case GET_DISTRIBUTOR: {
      return { ...state, ...action.payload }
    }
    case CALL_API: {
      return {
        ...state,
        loading: true,
      }
    }
  }
}
const getDataWarehouseDisAgent = async (id: string) => {
  try {
    const params = stringify(
      { saleSupervisorID: id, ...defaultFilters },
      defaultStringifyOption
    )
    const res = await axiosInstance.get(
      `/v1/distributor-agents/agent-warehouses?${params}`
    )
    const data = getResponseData<any>(res)

    const warehouses = data?.saleSupervisor?.warehouse || []

    if (isArray(warehouses) && warehouses.length > 0) {
      let existDistributor = {}
      const distributors = warehouses?.reduce((prev, curr) => {
        const { distributor } = curr
        if (existDistributor[distributor?.id]) {
          return prev
        }
        existDistributor = { ...existDistributor, [distributor?.id]: true }
        return [...prev, distributor]
      }, [])
      return { warehouses, distributors }
    }
    return { warehouses: [], distributors: [] }
  } catch (error) {
    console.log('🚀 ~ getWarehouse ~ error:', error)
  }
}
const getDistributor = async (isExactEmail, currentUserEmail) => {
  try {
    const params = stringify(
      isExactEmail
        ? {
            exactOwnerEmail: currentUserEmail,
          }
        : {
            ownerEmail: currentUserEmail,
          }
    )
    const res = await axiosInstance.get(`/v1/distributors?${params}`)
    const distributors = getResponseData<Distributor>(res)

    return distributors?.[0]
  } catch (error) {
    console.log('Get distributor error', error)
  }
}

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(actionReducer, initialState)
  const { data: session } = useSession()
  const { userRoles } = useACL()
  const isRoleSaleSupervisor = useMemo(
    () => isSaleSupervisor(userRoles),
    [userRoles]
  )
  const isRoleDistributor = useMemo(() => isDistributor(userRoles), [userRoles])

  const isRoleWarehouseOwner = useMemo(
    () =>
      userRoles.some((r) =>
        [ADMIN_MARKETPLACE, SALE_SUPERVISOR, DISTRIBUTOR_STAFF].includes(r)
      ),
    [userRoles]
  )
  const isRoleMasterDistributorHub = useMemo(
    () =>
      isIndia &&
      userRoles.some((r) => [MASTER_DISTRIBUTOR_HUB, HUB_MANAGER].includes(r)),
    [userRoles]
  )
  const getInitialDistributor = async () => {
    const email = get(session, 'user.email', []) as string
    dispatch({ type: CALL_API })
    const data = await getDistributor(false, email)
    dispatch({
      type: GET_DISTRIBUTOR,
      payload: { distributor: data, loading: false },
    })
  }

  const getInitialValue = async () => {
    const email = get(session, 'user.email', []) as string
    const data = await getDataWarehouse(email)
    dispatch({
      type: GET_WAREHOUSE_DATA_ROLE_ADMIN_MARKETPLACE,
      payload: { ...data },
    })
  }

  const getInitialWarehouseSaleSupervisor = async () => {
    const id = get(session, 'user.id', '') as string
    const data = await getDataWarehouseDisAgent(id)
    dispatch({
      type: GET_WAREHOUSE_DATA_ROLE_ADMIN_MARKETPLACE,
      payload: { ...data },
    })
  }
  const getInitialWarehouseDistributorHub = async () => {
    try {
      const res = await axiosInstance.get(`/v1/users/me/authorize`)
      const data = getResponseData<IAuthentication>(res)
      const { authorizedDistributor, authorizedWarehouse } = data

      if (data) {
        dispatch({
          type: GET_DATA_WAREHOUSE_MASTER_DISTRIBUTOR_HUB,
          payload: {
            warehouses: [
              authorizedWarehouse?.ownedWarehouse,
              ...authorizedWarehouse?.warehouse,
            ].filter((item) => item),
            distributors: [
              authorizedDistributor?.ownedDistributor,
              ...authorizedDistributor?.distributor,
            ].filter((item) => item),
            distributor: authorizedDistributor?.ownedDistributor,
          },
        })
      }
    } catch (error) {
      console.log('🚀 ~ getWarehouse ~ error:', error)
    }
  }

  useEffect(() => {
    if (session?.user && isRoleSaleSupervisor) {
      getInitialWarehouseSaleSupervisor()
      return
    }
    if (session?.user && isRoleWarehouseOwner) {
      getInitialValue()
      return
    }
    if (session?.user && isRoleMasterDistributorHub) {
      getInitialWarehouseDistributorHub()
      return
    }
    if (session?.user && isRoleDistributor) {
      getInitialDistributor()
      return
    }
  }, [
    session?.user,
    isRoleMasterDistributorHub,
    isRoleWarehouseOwner,
    isRoleSaleSupervisor,
  ])

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export const useAppContext = () => useContext(store)
