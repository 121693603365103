export const DISTRIBUTOR_PROFILE_EN = {
  name: 'Name',
  ownerName: 'Owner Name',
  ownerEmail: 'Owner Email',
  ownerPhone: 'Owner Phone',
  contactName: 'Contact Name',
  contactPhone: 'Contact Phone',
  billingAddressDetail: 'Address Detail',
  shippingAddressDetail: 'Address Detail',
  isActive: 'Active',
  bankName: 'Bank Name',
  bankAccountNumber: 'Account Number',
  bankAccountName: 'Account Name',
  bankBranchCode: 'Branch Code',
  bankCollectionName: 'Bank Name',
  bankCollectionAccountNumber: 'Account Number',
  bankCollectionAccountName: 'Account Name',
  bankCollectionBranchCode: 'Branch Code',
  taxCode: 'Tax Code/GSTIN',
  type: 'Classification Distributor',
  mailGroups: 'Group Email',
  brandIDs: 'Brand',
  brandGroupIDs: 'Brand Group',
  categoryIDs: 'Categories',
  industry: 'Industry',
  accountantEmail: 'Accountant Email',
  billingAddressCoordinateLat: 'Latitude',
  billingAddressCoordinateLong: 'Longitude',
  shippingAddressCoordinateLat: 'Latitude',
  shippingAddressCoordinateLong: 'Longitude',

  distributorProfileManagement: 'Distributor Profile Management',
  distributorName: 'Distributor Name',
  distributorCode: 'Code',
  classificationDistributor: 'Classification Distributor',
  normal: 'Normal',
  masterDistributorHub: 'Master Distributor Hub',
  category: 'Categories',
  coordinate: 'Coordinate',
  note: 'Note',
  brandDistributor: 'Distributed brand',
  license: 'License',
  distributorBrandGroupID: 'Distributor Brand Group',
  distributorBrandID: 'Distributor Brand',
  isDistributorBrand: 'Distributor Brand',
  separateFulfillmentOrder: 'Separate Fulfillment Order',
  distributorRegister: 'Distributor Register Management',
  all: 'ALL',
  need_approve: 'NEED APPROVE',
  approved: 'APPROVED',
  rejected: 'REJECTED',
  active: 'ACTIVE',
  inactive: 'IN-ACTIVE',
  'in-active': 'IN-ACTIVE',
  limitDebtAmount: 'Debt Available',
}
