import {
  PAYMENT_REQUEST_ALL,
  PAYMENT_REQUEST_AWAITING_APPROVE,
  PAYMENT_REQUEST_CANCELLED,
  PAYMENT_REQUEST_CREATED,
  PAYMENT_REQUEST_DEBT,
  PAYMENT_REQUEST_PAID,
  PAYMENT_REQUEST_REJECTED_INVOICE,
} from 'utils/paymentRequest'

const PAYMENT_REQUEST_STATUSES_EN = {
  [PAYMENT_REQUEST_ALL]: 'ALL',
  [PAYMENT_REQUEST_CREATED]: 'CREATED',
  [PAYMENT_REQUEST_PAID]: 'PAID',
  [PAYMENT_REQUEST_CANCELLED]: 'CANCELLED',
  [PAYMENT_REQUEST_AWAITING_APPROVE]: 'AWAITING APPROVE',
  [PAYMENT_REQUEST_DEBT]: 'DEBT',
  [PAYMENT_REQUEST_REJECTED_INVOICE]: 'REJECTED INVOICE',
}
export const PAYMENT_REQUEST_EN = {
  title: 'Payment Request',
  pageName: 'Purchase Request',
  refresh: 'Refresh',
  PRNumber: 'PR Number',
  warehouse: 'Warehouse',
  distributor: 'Distributor',
  brandGroup: 'Brand Group',
  requestDate: 'Request Date',
  currentStatus: 'Current Status',
  startDate: 'Start Date',
  endDate: 'End Date',
  search: 'Search',
  reset: 'Reset',
  copyPurchaseRequest: 'Copy Purchase Request',
  copyExistingPR: 'Copy From Existing Purchase Request',
  selectWarehouse: 'Select Warehouse',
  createdDate: 'Created Date',
  createdBy: 'Created By',
  requestBy: 'Request By',
  leadTime: 'Lead Time',
  dayAgo: 'day ago',
  monthAgo: 'month ago',
  yearAgo: 'year ago',
  pre: 'Pre',
  next: 'Next',
  records: 'records',
  page: 'page',
  status: 'Status',
  items: 'Items',
  addItem: 'Add Item',
  selectSKUs: 'Select SKUs',
  selectCategory: 'Select Category',
  productName: 'Product Name',
  poPrice: 'PO Price',
  salePrice: 'Sale Price',
  image: 'Image',
  ok: 'OK',
  quantity: 'Quantity',
  quantityRequired: 'Quantity is Required!',
  packType: 'Pack Type',
  action: 'Action',
  cancel: 'Cancel',
  create: 'Create',
  name: 'Name',
  brandGroupRequired: 'Please select brand group!',
  brand: 'Brand',
  update: 'Update',
  statusHistory: 'Status History',
  paymentRequestNumber: 'Payment Request Number',
  relatedDocument: 'Related Document',
  paymentRequest: 'Payment Request',
  paymentFrom: 'Payment From',
  paymentTo: 'Payment To',
  partner: 'Partner',
  paymentDetails: 'Payment Details',
  noteFromVigoFinance: 'Note From Vigo Finance',
  distributorPO: 'Distributor PO',
  creationTime: 'Creation Time',
  totalValue: 'Total Value',
  relatedDocuments: 'Related Documents',
  bank: 'Bank',
  bankName: 'Bank Name',
  bankAccount: 'Bank Account',
  transferNote: 'Transfer Note',
  amount: 'Amount',
  selectPartner: 'Select Partner',
  dueDate: 'Due Date',
  paymentFromTo: 'Payment From - To',
  to: 'To',
  totalAmount: 'Total Amount',
  collectedAmount: 'Collected Amount',
  debtAmount: 'Debt Amount',
  overDue: 'Overdue',
  recordDebt: 'Record Debt',
  maskAsPaid: 'Mark as paid',
  relatedPerson: 'Related Person Information',
  paymentInformation: 'Payment Information',
  noteFrom: 'Note from QA Company Distributor Finance',
  relatedInvoice: 'Related Invoice',
  rejectInvoice: 'Reject Invoice',
  noteFromFinance: 'Note from Vigo Finance',
  noteFromDistributor: 'Not from distributor',
  messageCancel: 'Are you sure to cancel this? This action cannot be undone.',
  referenceDocument: 'Reference Document',
  paymentNote: 'Payment Note',
  confirmRecordDebt: 'Confirm Debt',
  debtReminder: 'Debt Reminder Date',
  updateInvoice: 'Update Invoice',
  saveNote: 'Update Note',
  upcomingAndDueDebt: 'Upcoming debt & Due debt',
  request: 'requests',
  dueDebt: 'Due debt',
  upcomingDebt: 'Upcoming debt',
  pleaseReminder: 'Please send reminder!',
  awaitingResult:
    'After updating the invoice, please click Update and wait for the result.',
  upcomingDebtAndDue: 'Due - upcoming debt',
  payableDebt: 'Payable debt',
  greater_than_0: 'Greater than 0 ₫ ( > 0 ₫ ) ',
  equal_0: 'Equals 0 ₫  ( = 0 ₫ ) ',
  distributorPaymentDetails: 'Distributor payment details',
  paymentDate: 'Payment Date',
  vigoPaymentRequest: 'Vigo PO Payment Request',
  nextPaymentNumber: 'Request Number',
  validateDebt:
    'The debt amount {debtAmount} ₫ exceeds the available debt limit {debtAvailable} ₫ of the distributor {distributorName}!',
  collectAmount: 'Collect Amount',
  normal: 'Normal',
  parent: 'Parent',
  child: 'Child',
  ...PAYMENT_REQUEST_STATUSES_EN,
}
